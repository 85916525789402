import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import YouTube from "react-youtube";
import ReactPlayer from 'react-player/lazy'

const PlayVideo = (props) => {
    const [muted, setMuted] = useState(false); 
    const [render, setRender] = useState(false); 
    const saysWho = () => {
        const { userAgent } = navigator
        let match = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []
        let temp
      
        if (/trident/i.test(match[1])) {
          temp = /\brv[ :]+(\d+)/g.exec(userAgent) || []
      
          return `IE ${temp[1] || ''}`
        }
      
        if (match[1] === 'Chrome') {
          temp = userAgent.match(/\b(OPR|Edge)\/(\d+)/)
      
          if (temp !== null) {
            return temp.slice(1).join(' ').replace('OPR', 'Opera')
          }
      
          temp = userAgent.match(/\b(Edg)\/(\d+)/)
      
          if (temp !== null) {
            return temp.slice(1).join(' ').replace('Edg', 'Edge (Chromium)')
          }
        }
      
        match = match[2] ? [ match[1], match[2] ] : [ navigator.appName, navigator.appVersion, '-?' ]
        temp = userAgent.match(/version\/(\d+)/i)
      
        if (temp !== null) {
          match.splice(1, 1, temp[1])
        }
      
        return match.join(' ')
      }
      
     
    useEffect(()=>{
        if(!render){
            setRender(true) 
            if(saysWho().includes('Safari')) {
                setMuted(true) 
            } else{
                setMuted(false)
            }
        } 
    })
    return (
        <React.Fragment>
            {props.isOpen && 
                <div className="full-video">
                    {!isMobile && <div className="d-lg-block d-none">
                        <YouTube
                            videoId={props.videoId}
                            opts={{
                                height: "100%",
                                width: "100%",
                                playerVars: {
                                    autoplay: 1,
                                    disablekb: 1,
                                    controls: 0,
                                    mute: 0
                                }
                            }}
                        />
                    </div>}
                    {isMobile && <div className="d-lg-none d-block">
                        <ReactPlayer 
                            url={`https://www.youtube.com/watch?v=${props.videoId}`} 
                            width="100%" 
                            height="100%" 
                            playing 
                            controls={isMobile}
                            muted={muted}
                        />
                    </div>}
                </div>
            }
        </React.Fragment>
    )
};

export default React.memo(PlayVideo);